









































import { Component, Vue } from 'vue-property-decorator';
import AddCustomInvestorGoalWizardBeforeAdjustGoalsViewModel
  from '@/vue-app/view-models/components/goals-dashboard/add-goals/add-custom-investor-goal-wizard-before-adjust-goals-view-model';

@Component({ name: 'AddCustomInvestorGoalWizardBeforeAdjustGoals' })
export default class AddCustomInvestorGoalWizardBeforeAdjustGoals extends Vue {
  add_custom_goal_wizard_before_view_model = Vue.observable(
    new AddCustomInvestorGoalWizardBeforeAdjustGoalsViewModel(this),
  );
}
